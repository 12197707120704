<!--
Copyright (C) 2024 Checkmk GmbH - License: GNU General Public License v2
This file is part of Checkmk (https://checkmk.com). It is subject to the terms and
conditions defined in the file COPYING, which is part of this source code package.
-->
<template>
  <label><slot /></label>
</template>

<style scoped>
label {
  display: inline-block;
  padding: 3px 0;
}
</style>
