<!--
Copyright (C) 2024 Checkmk GmbH - License: GNU General Public License v2
This file is part of Checkmk (https://checkmk.com). It is subject to the terms and
conditions defined in the file COPYING, which is part of this source code package.
-->
<template>
  <svg
    width="2.508mm"
    height="2.1721mm"
    version="1.1"
    viewBox="0 0 2.508 2.1721"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m1.2539 2.1718-1.254-2.1721h2.508l-0.62702 1.086z" fill="currentColor" />
  </svg>
</template>
